import { getSignatures } from "../commonDynmicForms/signature";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { FillableBy } from "../dynamicCompentencyForms/form_enums";

const responsive_input_fields_span = {
  labelSpan: { xxl: 4, xl: 6, md: 9, sm: 12, xs: 16 },
  inputSpan: { xxl: 12, xl: 12, md: 12, sm: 12, xs: 8 },
  offsetSpan: { xxl: 8, xl: 6, md: 3, sm: 0, xs: 0 },
};


const nrp_fields = [
  {
    fillable_by : FillableBy.Evaluator,
    label: 'NRP Arterial cannula:',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,
    value: "",
    placeholder: "Enter here..."
  },
  {
    fillable_by : FillableBy.Evaluator,
    label: 'NRP venous cannula:',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,

    value: "",
    placeholder: "Enter here..."
  },
  {
    fillable_by : FillableBy.Evaluator,
    label: 'NRP circuit flow isolating clamp(s):',
    type: "a-input",
    span: 24,
    ...responsive_input_fields_span,
    value: "",
    placeholder: "Enter here..."
  }
];
export default {
  fillable_by:FillableBy.Both,
  title:
    "Normothermic Regional Perfusion (NRP) Perfusionist (CCP) Cognitivfe Competency Assessment",
  sections: [
    {
      type: "questions",
      Layout: 'horizontal',
      fillable_by : FillableBy.Employee,
      fields: [
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: "Normothermic Regional Perfusion (NRP) is an in-situ extracorporeal technology used in donation after cardiac death (DCD) organ donors.",
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: "Specific policy regarding NRP types, declaration of death, hands-off period, Heparin administration, and NRP circuit prime drugs will vary both between OPOs and between donor hospitals within an OPO territory. The NRP Perfusionist should always confirm all of the above on the pre-case conference call with the OPO and should direct any questions to the KPS NRP Clinical Director.",
          isAnswerCorrect : false
        },
        {
          type: "fields",
          title: 'In abdominal NRP (A-NRP), please list the appropriate cannula and clamp placement location(s):',
          span: 24,
          field: nrp_fields,
          isAnswerCorrect : false
        },
        {
          type: "fields",
          title: 'In thoracoabdominal NRP (TA-NRP), please list the appropriate cannula and clamp placement location(s):',
          span: 24,
          field: nrp_fields,
          isAnswerCorrect : false
        },
        {
          type: "fields",
          title: 'In hybrid abdominal NRP (Hybrid A-NRP), please list the appropriate cannula and clamp placement location(s):',
          span: 24,
          field: nrp_fields,
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: "A-NRP does not perfuse the heart or lungs",
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: "Hybrid A-NRP does not perfuse the heart or lungs",
          isAnswerCorrect : false
        },
        {
          title: "Which of the following is not a NRP circuit prime drug per KPS NRP protocol?",
          type: "a-radio",
          span: 24,
          value: [],
          options: [
            "10kiu Heparin",
            "50 mEq Sodium Bicarbonate",
            "12.5g Mannitol",
            "2g Magnesium Sulfate",
            "500mg Solu-Medrol",
            "50mL 25% Albumin",
            "100mg Rocuronium (per OPO/Donor Hospital/Transplant center policy)",
          ].map((item) => ({ text: item, span: 24 })),
          isAnswerCorrect : false
        },
        {
          title: "What is the target NRP flow in A-NRP? (select all that apply)",
          type: "a-checkbox-group",
          span: 24,
          value: [],
          options: [
            "4-5 liters per minute",
            "1.5-2 liters per minute",
            "2.5-3.5 liters per minute",
            "As ordered by NRP surgeon and discussed at beginning of case/throughout case based on clinical scenario",
          ].map((item) => ({ text: item, span: 24 })),
          isAnswerCorrect : false
        },
        {
          title: "What is the target NRP flow in TA-NRP? ",
          type: "a-radio",
          span: 24,
          value: [],
          options: [
            "2.5-3.5 liters per minute",
            "1.8 cardiac index per BSA",
            "2.0-2.4 cardiac index per BSA",
            "3-5 liters per minute"
          ].map((item) => ({ text: item, span: 24 })),
          isAnswerCorrect : false
        },
        {
          type: 'draggable',
          span: 24,
          title: "Please place the following NRP case events in chronological order using the numbers 1- 15 with 1 occurring first and 15 occurring last.",
          value: [
            "The NRP perfusionist confirms surgical clamps are in place to isolate NRP flow to targeted regions only and initiates NRP circuit flow",
            "All organ recovery and NRP staff must leave and remain out of the OR",
            "Surgical incision for organ donation",
            "Pre-case conference with all case stakeholders and clinical participants",
            "NRP circuit setup and priming in the donor OR",
            "NRP arterial cannulation",
            "Declaration of death by donor hospital pronouncing physician",
            "Heparin administration (if allowed pre-mortem by hospital and OPO policy)",
            "NRP venous cannulation, if ordered by NRP Surgeon open and drain",
            "NRP setup checklist completed",
            "Surgical time out and moment of honor completed",
            "Cannulation hallmark events confirmed and initiation checklist completed",
            "Surgical team re-enters OR after instructed by designated OPO staff",
            "Head vessel occlusion and venting complete and/or aortic cross clamp placed superior to NRP arterial cannulation site",
            "Withdrawal of life sustaining therapy",
          ].map((item, index) => ({ text: item, order: index + 1 })),
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: "A-NRP does not perfuse the heart or lungs.",
          isAnswerCorrect : false
        },
        {
          type: "inline-input",
          span: 24,
          value: [],
          text: "In %input% NRP (type), reintubation and ventilation needs to occur after the initiation of NRP circuit flow and securement of the cannulas. In %input% and %input% types of NRP, reintubation is not necessary.",
          isAnswerCorrect : false
        },
        {
          title: "With regard to packed red blood cell transfusion during NRP, which of the following statements are true? (select all that apply)",
          type: "a-radio",
          span: 24,
          value: [],
          options: [
            "Blood products must be present in the OR prior to removal of life sustaining therapy",
            "Each unit of blood must be checked by two clinicians prior to administration",
            "Any additional checks and documentation required by the OPO and donor hospital must be completed in additional to KPS Keyport documentation",
            "Any unused blood products must be returned to the blood bank per donor hospital policy"
          ].map((item) => ({ text: item, span: 24 })),
          isAnswerCorrect : false
        },
        {
          type: "fields",
          title: 'Please list the average target NRP perfusion times for the following targeted organs:',
          span: 24,
          field: [
            {
              label: 'Heart only:',
              type: "a-input",
              span: 24,
              ...responsive_input_fields_span,
              value: "",
              placeholder: "Enter here...",
              suffix: "minutes"
            },
            {
              label: 'Liver and kidneys:',
              type: "a-input",
              span: 24,
              ...responsive_input_fields_span,
              value: "",
              placeholder: "Enter here...",
              suffix: "minutes"
            },
            {
              label: 'Kidneys only:',
              type: "a-input",
              span: 24,
              ...responsive_input_fields_span,
              value: "",
              placeholder: "Enter here...",
              suffix: "minutes"
            },
            {
              label: 'Heart, Liver, and Kidneys:',
              type: "a-input",
              span: 24,
              ...responsive_input_fields_span,
              value: "",
              placeholder: "Enter here...",
              suffix: "minutes"
            }
          ],
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: " At the end of an NRP run, the circuit blood and any preservation solution drained into the cardiotomy reservoir must be sent to a Neptune, alternative vacuum assisted containment device, or designated closed-lid container.  The empty circuit should be placed in a hospital-designated biohazard bag and receptacle.",
          isAnswerCorrect : false
        },
        {
          type: "a-switch",
          span: 24,
          value: null,
          text: " After the disposable circuit is disposed, the NRP equipment must be thoroughly cleaned with hospital-grade cleaning wipes.",
          isAnswerCorrect : false
        },
      ]
    },
    {
      type: "scoreInfo",
      Layout: 'horizontal',
      fillable_by : FillableBy.Evaluator,
      fields: [
        {
          type: "inline-input",
          span: 24,
          value: [0, 17],
          text: "Score : %input% / %input%",
        },
        {
          span:24,
          type: "text",
          text: "Passing score is 17/17.  Any questions answered incorrectly were reviewed with trainer/validator and corrected by the user.",
        },
      ]
    },
    {
      title: "Signatures",
      type: "signature",
      fields: getSignatures(FillableBy.Both)
    },
  ]
}